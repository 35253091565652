<template>
  <a-modal
    title="规格值新增"
    :width="500"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-row :gutter="24">
        <a-col :md="24" :sm="24">
          <a-form :form="form">
            <a-form-item label="规格值名称" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入"
                v-decorator="['name', { rules: [{ required: true, min: 2, message: '至少输入两位！' }] }]"
              />
            </a-form-item>
          </a-form>
          <!-- <a-form :form="form">
            <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
              <a-input
                placeholder="请输入排序"
                v-decorator="[
                  'sort',
                  {
                    rules: [{ required: true, min: 1, message: '请输入排序！' }],
                  },
                ]"
                v-model="sort"
              />
            </a-form-item>
          </a-form> -->
        </a-col>
      </a-row>

    </a-spin>
  </a-modal>
</template>

<script>
import { specValueAdd } from '@/api/modular/mallLiving/specValue'

export default {
  data() {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      specId: ''
    }
  },
  methods: {
    
    // 初始化方法
    add(specId) {
      console.log(specId)
      this.specId = specId
      this.visible = true
    },
    
    handleSubmit() {
      this.confirmLoading = true
      const {
        form: { validateFields },
      } = this

      validateFields((errors, values) => {
        if (!errors) {
          values.specId = this.specId
          specValueAdd(values).then(res =>{
            if(res.success){
              this.$message.success('规格值添加成功')
              this.confirmLoading = false
              this.$emit('ok', values)
              this.handleCancel()
            }
          }).finally((res) => {
            setTimeout(()=>{
              this.confirmLoading = false
            }, 600)
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      this.form.resetFields()
      this.visible = false
      // 清理子表单中数据
      this.data = []
    },
  },
}
</script>
<style scoped>
.flex {
  display: flex;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>